import { BehaviorSubject } from 'rxjs'
import http from '@/http-client'
import { MaterielCategoryResponse, PrescriptionModel, PrestationModel, PrestationAgendaResponseModel } from '@/models/prescriptions-model'
import { DocumentService } from './patient-document-service'
import { PrestationReportModel, PrestationReportDataModel } from '@/models/prescripton-report-model'
import Commons from '@/components/shared/Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'

export class PatientPrescriptionService {
  private static instance: PatientPrescriptionService
  private documentService = DocumentService.getInstance()
  private _prestationDialogModelSource = new BehaviorSubject<boolean>(false)
  private _prescriptionSelectedSource = new BehaviorSubject<PrescriptionModel>(
    { date: Commons.GetTodayFormatted(), statusId: 1 }
  )

  private _prestationSelectedSource = new BehaviorSubject<PrestationModel>(
    { date: Commons.GetTodayFormatted() }
  )

  public prestationDialogModel$ = this._prestationDialogModelSource.asObservable()
  public prescriptionSelected$ = this._prescriptionSelectedSource.asObservable()
  public prestationSelected$ = this._prestationSelectedSource.asObservable()
  public activeIndex = 0
  public categories: MaterielCategoryResponse[] = []

  constructor () {
    this.getMaterielCategories().then(cats => {
      this.categories = cats
    })
  }

  public static getInstance (): PatientPrescriptionService {
    if (!PatientPrescriptionService.instance) {
      PatientPrescriptionService.instance = new PatientPrescriptionService()
    }
    return PatientPrescriptionService.instance
  }

  public setTabIndex (idx: number) {
    this.activeIndex = idx
  }

  public getTabIndex () {
    return this.activeIndex
  }

  public async getDoctors (existingId?: number) {
    const response = await http.get(`/dossierPatient/prescriptions/doctors/${existingId ?? ''}`)
    return (response.data) as { id: number; fullname: string } []
  }

  private async getMaterielCategories () {
    const response = await http.get('/dossierPatient/prestation/materiel-categories')
    return (response.data) as MaterielCategoryResponse[]
  }

  public updatePrescriptionSelected (prescription: PrescriptionModel) {
    this._prescriptionSelectedSource.next(prescription)
  }

  public updatePrestationSelected (prestation: PrestationModel) {
    this._prestationSelectedSource.next(prestation)
  }

  public updateprestationDialogModelSource (state: boolean) {
    this._prestationDialogModelSource.next(state)
  }

  // ==> prescription
  public async AddUpdatePrescription (dossierId: string, prescription: PrescriptionModel) {
    prescription.dossierId = prescription.dossierId ?? dossierId
    let fileId = ''
    if (prescription.file) {
      fileId = await this.documentService.uploadDocument(prescription.file as File, dossierId)
    }
    prescription.documentId = fileId
    return await http.post(`/dossierPatient/prescription/${dossierId}`, prescription)
  }

  // => document
  public async getDocumentURL (documentId: string) {
    return await this.documentService.getDocumentURL(documentId)
  }

  public async GetDossierPrescriptions (dossierId: string) {
    const response = await http.get(`/dossierPatient/prescriptions/${dossierId}`)
    return (response.data) as PrescriptionModel[]
  }

  public async deletePrescription (id: string | undefined) {
    if (!id) return
    return await http.delete(`/dossierPatient/prescription/${id}`)
  }

  // ==> prestation
  public async GetPatientPrestations (dossierId: string, appointmentId?: number) {
    if (!dossierId) {
      return []
    }
    const response = await http.get(`/dossierPatient/prestationsPatient/${dossierId}/${appointmentId ?? 0}`)
    return (response.data) as PrestationAgendaResponseModel[]
  }

  public async GetPatientMaterielPrestations (dossierId: string, date: string) {
    const response = await http.get(`/dossierPatient/materielPrestationsPatient/${dossierId}?date=${date}`)
    return (response.data) as PrestationAgendaResponseModel[]
  }

  public async HasPrestationsPatientAtLimit (dossierId: string, appointmentId?: number) {
    if (!dossierId) return false
    const response = await http.get(`/dossierPatient/hasPrestationsPatientAtLimit/${dossierId}/${appointmentId ?? 0}`)
    return (response.data) as boolean
  }

  public async AddUpdatePrestation (prescriptionId: string, prestation: PrestationModel) {
    if (!prescriptionId) return
    prestation.prescriptionId = prescriptionId
    return await http.post(`/dossierPatient/prestation/${prescriptionId}`, prestation)
  }

  public async GetPrescriptionPrestations (prescriptionId: string) {
    const response = await http.get(`/dossierPatient/prestations/${prescriptionId}`)
    return (response.data) as PrestationModel[]
  }

  public async deletePrescriptionPrestation (id: string | undefined) {
    if (!id) return
    return await http.delete(`/dossierPatient/prestation/${id}`)
  }

  // ==> Reports
  public async GetPrescriptionReportData (reportId: string) {
    const response = await http.get(`/dossierPatient/prescription/reportdata/${reportId}`)
    return (response.data) as PrestationReportDataModel
  }

  public async GetPrescriptionReports (prestationId: string) {
    const response = await http.get(`/dossierPatient/prescription/reports/${prestationId}`)
    return (response.data) as PrestationReportModel[]
  }

  public async AddUpdatePrescriptionReport (prestationId: string, report: PrestationReportModel) {
    if (!prestationId) return
    report.prestationId = prestationId
    return await http.post(`/dossierPatient/prescription/report/${prestationId}`, report)
  }

  public async DeletePrescriptionReport (id: string | undefined) {
    if (!id) return
    const response = await http.delete(`/dossierPatient/prescription/report/${id}`)
    return response.data as number
  }

  public async GetDossierPatientByPrestationId (prestationId: string) {
    const response = await http.get(`/dossierPatient/dossierByPrestation/${prestationId}`)
    return (response.data) as dossierPatientModel
  }
}
