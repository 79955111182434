import http, { fHttp } from '@/http-client'
import { DocumentInfoReq, DocumentInfoResponse } from '@/models/image-model'
import { BehaviorSubject } from 'rxjs'

export class DocumentService {
  private static instance: DocumentService
  private _uploadErrors = new BehaviorSubject<any>('')
  public uploadErrors$ = this._uploadErrors.asObservable()

  public static getInstance (): DocumentService {
    if (!DocumentService.instance) {
      DocumentService.instance = new DocumentService()
    }
    return DocumentService.instance
  }

  // ==> documents
  public async uploadDocument (file: File, dossierId: string, typeId?: string, description?: string) {
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('dossierId', dossierId)
    formData.append('mimeType', file.type)
    formData.append('name', file.name)
    if (typeId) formData.append('typeId', typeId)
    if (description) formData.append('description', description)
    const response = await fHttp.post(`/dossierPatient/documents/${dossierId}`, formData)
    return response.data as string
  }

  public async uploadDocuments (dossierId: string, documents: DocumentInfoReq[]) {
    return Promise.allSettled(documents.map(async (image, _, imgs) => {
      await this.uploadDocument(image.file as File, dossierId, image.typeId?.toString(), image.description)
        .catch((err) => {
          this._uploadErrors.next({ err, image })
        })
        .finally(() => {
          imgs.splice(imgs.indexOf(image))
        })
    }))
  }

  public async getDocumentURL (documentId: string) {
    const response = await http.get(`/dossierPatient/document/${documentId}`)
    return response.data as File
  }

  public async getPatientDocumentDivers (documentId: string) {
    const response = await http.get(`/dossierPatient/documents/${documentId}`)
    return response.data as DocumentInfoResponse[]
  }

  public async deletePatientDocument (id?: string) {
    if (id) {
      const res = await http.delete(`/dossierPatient/document/${id}`)
      return res.status as number
    }
  }
}
